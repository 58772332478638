import { LocalesList } from '@constants/locales/localesList'
import { regExpressions } from '@utils/string/regularExpressions'

export const getUrlWithoutParams = (url: string) => url.split('?')[0]

export const getVersionFromPath = (path?: string) => {
  const match = path?.match(/\/v(\d+)/)
  if (match) {
    return match[1]
  }
  return
}

export const checkHttp = (url: string) => {
  return regExpressions.http.test(url)
}

export const addLanguageToUrl = (url: string, lang: LocalesList) => {
  if (lang === LocalesList.EN) return url

  if (!checkHttp(url)) {
    return `${lang}${url}`
  }

  return url.replace(regExpressions.httpApi, `$1/${lang}$2`)
}
