export enum LocalesListIds {
  AR = 'ar',
  DE = 'de',
  EL = 'el',
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  IT = 'it',
  JA = 'ja',
  KO = 'ko',
  NB = 'nb',
  NL = 'nl',
  PL = 'pl',
  PT = 'pt',
  RU = 'ru',
  SV = 'sv',
  TR = 'tr',
  'ZH-HANS' = 'zh-hans',
  'ZH-HANT' = 'zh-hant',
}

export enum LocalesList {
  AR = 'ar',
  CIMODE = 'cimode',
  CN = 'cn',
  DE = 'de',
  EL = 'el',
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  IT = 'it',
  JP = 'jp',
  KO = 'ko',
  NB = 'nb',
  NL = 'nl',
  PL = 'pl',
  PT = 'pt',
  RU = 'ru',
  SV = 'sv',
  TR = 'tr',
  TW = 'tw',
}
