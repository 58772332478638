// @ts-check
/**
 @type {import('next-i18next').UserConfig}
 */

const HttpBackend = require('i18next-http-backend/cjs')
const getConfig = require('next/config')

const localesArray = [
  "en", "de", "es", "pt", "it", "fr", "ru",
  "cn", "tw", "ko", "tr", "el",
  "nl", "sv", "nb", "ar", "pl", "jp"
]

const getUrl = ([lng]) => {
  const publicRuntimeConfig = getConfig.default()?.publicRuntimeConfig
  const backendUrl = publicRuntimeConfig?.backendApiUrl

  if (lng === 'en') {
    return `${backendUrl}/api/v1/translation`
  }
  return `${backendUrl}/${lng}/api/v1/translation`
}

// deep merge json
const deepMerge = (target, source) => {
  for (const key in source) {
    if (source[key] instanceof Object && key in target) {
      Object.assign(source[key], deepMerge(target[key], source[key]));
    }
  }
  return { ...target, ...source };
};

// Load local fallback file
const loadLocalFallback = () => {
  try {
    return require(`./public/locales/en/fallback.json`);
  } catch (error) {
    console.warn(`Local translation not found`);
    return {};
  }
};

const getBackendOptions = () => {
  const publicRuntimeConfig = getConfig.default()?.publicRuntimeConfig

  return {
    customHeaders: {
      'X-API-User-Key': publicRuntimeConfig?.xApiUserKey || '',
    },
    loadPath: getUrl,
    parse: (data) => {
      const localTranslations = loadLocalFallback()
      const remoteTranslations = JSON.parse(data);

      return deepMerge(localTranslations, remoteTranslations);
    },
  }
}

module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales: localesArray,
    localeDetection: false
  },
  trailingSlash: true,
  serializeConfig: false,
  use:  [HttpBackend],
  backend: getBackendOptions() ,
  fallbackLng: {
    default: ['en'],
  },
  ns: ['common'],
  defaultNS: 'common',
  debug: false,
  preload: ['en'],
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  localePath: typeof window === 'undefined' ? require('path').resolve('./public/locales') : 'common/locales',
}
